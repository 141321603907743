@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background: #303343;
  border-radius: 4px;
}
*::-webkit-scrollbar-thumb:hover {
  background: lightgrey;
}
.navtab-ctr::-webkit-scrollbar {
  display: none;
}
